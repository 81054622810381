import React from "react"
import { graphql } from "gatsby"
import SEO from "../../components/seo"
import Layout from "../../components/layout"

import "./index.css"

const AboutPage = ({ data }) => {
  return (
    <Layout title="About">
      <SEO title="About | Leonie Freudenberger" />
      <article>
        <div
          className="content"
          dangerouslySetInnerHTML={{
            __html: data.contentfulHomepage.aboutText.childMarkdownRemark.html,
          }}
        ></div>
      </article>
    </Layout>
  )
}

export const query = graphql`
  {
    contentfulHomepage(id: { eq: "4391cc10-a551-5b94-8e58-5cdc5e937532" }) {
      aboutText {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export default AboutPage
